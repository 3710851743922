import React from "react";
// If you're on MUI 5, switch to @mui/material
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    ListItemText,
    Checkbox,
    OutlinedInput,
    Box,
    Chip,
    ListItemIcon
} from "@mui/material";

export default function MultiSelectWithSelectAll({
    label,
    options = [],
    value,
    onChange
}) {
    // Are all items currently selected?
    const allSelected = options.length > 0 && value.length === options.length;

    // Called whenever the user selects or deselects from the drop-down
    const handleSelectChange = (e) => {
        let newValue = e.target.value; // array of item values user selected

        // If user clicked our special "Select All" item (with value="__all"):
        if (newValue.includes("__all")) {
            // If all are already selected, clear them
            if (allSelected) {
                newValue = [];
            } else {
                // Otherwise select them all
                newValue = options.map((o) => o.value);
            }
        }

        // Ensure it's always an array of strings/numbers
        if (typeof newValue === "string") {
            newValue = newValue.split(",");
        }

        // Call the parent's onChange
        onChange(newValue);
    };

    return (
        <FormControl variant="outlined" style={{ width: "100%" }}>
            <InputLabel id={`${label}-label`}>{label}</InputLabel>
            <Select
                labelId={`${label}-label`}
                multiple
                value={value}
                onChange={handleSelectChange}
                label={label}
                input={<OutlinedInput label={label} />}
                // Show selected items as chips:
                renderValue={(selectedArray) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selectedArray.map((val) => {
                            const matched = options.find((opt) => opt.value === val);
                            const chipLabel = matched ? matched.label : val;
                            return <Chip key={val} label={chipLabel} />;
                        })}
                    </Box>
                )}
            >
                {/* "Select All" at the top */}
                <MenuItem value="__all">
                    <ListItemIcon>
                        <Checkbox
                            checked={allSelected}
                            indeterminate={value.length > 0 && value.length < options.length}
                        />
                    </ListItemIcon>
                    <ListItemText primary="Select All" />
                </MenuItem>

                {/* Normal items (no checkboxes here, just text) */}
                {options.map((opt) => (
                    <MenuItem key={opt.value} value={opt.value}>
                        {opt.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
