import { SvgIcon } from '@mui/material'
import React from 'react'

export default function ConditionIcon({ width = 50, height = 50, mb = 2 }) {
    return (
        <SvgIcon sx={{ width, height, mb }}>
            <svg width="24" height="24" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_2054_399)">
                    <path d="M12.5 8.60716C12.2994 8.60716 12.1377 8.44464 12.1377 8.24311V4.86917C12.1377 4.66765 12.2994 4.50513 12.5 4.50513C12.7006 4.50513 12.8623 4.66765 12.8623 4.86917V8.24311C12.8623 8.44464 12.7006 8.60716 12.5 8.60716Z" fill="white" />
                    <path d="M21.0468 12.3841H17.5012C17.3007 12.3841 17.1389 12.2216 17.1389 12.0201C17.1389 11.8185 17.3007 11.656 17.5012 11.656H21.0468C21.2474 11.656 21.4091 11.8185 21.4091 12.0201C21.4091 12.2216 21.2474 12.3841 21.0468 12.3841Z" fill="white" />
                    <path d="M21.0469 18.4949C20.8463 18.4949 20.6846 18.3324 20.6846 18.1308V12.0201C20.6846 11.8185 20.8463 11.656 21.0469 11.656C21.2475 11.656 21.4092 11.8185 21.4092 12.0201V18.1308C21.4092 18.3324 21.2475 18.4949 21.0469 18.4949Z" fill="white" />
                    <path d="M7.49222 12.3841H3.94667C3.7461 12.3841 3.58435 12.2216 3.58435 12.0201C3.58435 11.8185 3.7461 11.656 3.94667 11.656H7.49222C7.69279 11.656 7.85454 11.8185 7.85454 12.0201C7.85454 12.2216 7.69279 12.3841 7.49222 12.3841Z" fill="white" />
                    <path d="M3.95314 18.4949C3.75257 18.4949 3.59082 18.3324 3.59082 18.1308V12.0201C3.59082 11.8185 3.75257 11.656 3.95314 11.656C4.15371 11.656 4.31546 11.8185 4.31546 12.0201V18.1308C4.31546 18.3324 4.15371 18.4949 3.95314 18.4949Z" fill="white" />
                    <path d="M7.49224 23H0.362319C0.161749 23 0 22.8375 0 22.636V18.1309C0 17.9294 0.161749 17.7668 0.362319 17.7668H7.49224C7.69281 17.7668 7.85456 17.9294 7.85456 18.1309V22.636C7.85456 22.8375 7.69281 23 7.49224 23ZM0.724638 22.2784H7.13639V18.4949H0.724638V22.2784Z" fill="white" />
                    <path d="M24.6376 23H17.5077C17.3071 23 17.1454 22.8375 17.1454 22.636V18.1309C17.1454 17.9294 17.3071 17.7668 17.5077 17.7668H24.6376C24.8382 17.7668 24.9999 17.9294 24.9999 18.1309V22.636C24.9999 22.8375 24.8382 23 24.6376 23ZM17.8636 22.2784H24.2753V18.4949H17.8636V22.2784Z" fill="white" />
                    <path d="M16.065 5.23318H8.93507C8.7345 5.23318 8.57275 5.07066 8.57275 4.86913V0.364047C8.57275 0.162521 8.7345 0 8.93507 0H16.065C16.2656 0 16.4273 0.162521 16.4273 0.364047V4.86913C16.4273 5.07066 16.2656 5.23318 16.065 5.23318ZM9.29739 4.50509H15.7091V0.728095H9.29739V4.51159V4.50509Z" fill="white" />
                    <path d="M12.5 16.1676C12.4223 16.1676 12.3447 16.1416 12.2865 16.096L7.27869 12.3126C7.18811 12.241 7.13635 12.137 7.13635 12.02C7.13635 11.903 7.18811 11.799 7.27869 11.7275L12.2865 7.94398C12.4159 7.84647 12.5905 7.84647 12.7199 7.94398L17.7277 11.7275C17.8183 11.799 17.87 11.903 17.87 12.02C17.87 12.137 17.8183 12.241 17.7277 12.3126L12.7199 16.096C12.6552 16.1416 12.5776 16.1676 12.5064 16.1676H12.5ZM8.09391 12.02L12.5 15.3484L16.906 12.02L12.5 8.69158L8.09391 12.02Z" fill="#0DA3E2" />
                </g>
                <defs>
                    <clipPath id="clip0_2054_399">
                        <rect width="25" height="25" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </SvgIcon>
    )
}
