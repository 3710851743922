import { Box, Typography, Button, Grid2 } from '@mui/material'
import React from 'react'

export default function FormTemplate({ children, title, handleSubmit, isValid, onClose }) {
    return (
        <Box
            sx={{
                height: '75vh',
                width:"100%",
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
                mb: 2,
                mx: 'auto',
                maxHeight: '40rem'
            }}
            fullWidth={true}
        >
            <Typography variant='h4' align='center' sx={{ pt: 2, mb: 1.5, flexShrink: 0 }}>
                {title}
            </Typography>

            <Box
                sx={{
                    flex: 1,
                    overflowY: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: 0
                }}
            >
                <Box justifyContent='center' display='flex'>
                    <Grid2 container direction='row' justifyContent='center' spacing={0} sx={{ p: 2, maxWidth: '35rem' }}>
                        {children}
                    </Grid2>
                </Box>
            </Box>

            <Box
                justifyContent='center'
                display='flex'
                sx={{ flexShrink: 0, mt: 'auto', mb: 1 }}
            >
                <Grid2 container direction='row' justifyContent='center' spacing={0} sx={{ maxWidth: '25rem' }} >
                    <Button
                        onClick={handleSubmit}
                        aria-label='submit'
                        variant='contained'
                        fullWidth
                        disabled={!isValid}
                        sx={{ minWidth: '9rem', m: 1 }}
                    >
                        Submit
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={onClose}
                        fullWidth
                        sx={{ minWidth: '9rem', m: 1 }}
                    >
                        Cancel
                    </Button>
                </Grid2>
            </Box>
        </Box>
    )
}