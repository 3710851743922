import { SvgIcon } from '@mui/material';
import React from 'react';

export default function NewLeadIcon({ width = 50, height = 50, mb}) {
    return (
        <SvgIcon sx={{ width, height, mb }} fontSize="small">
            <svg width="42" height="49" viewBox="0 0 42 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_2055_494)">
                    <path d="M16.9067 48.8746C16.7813 48.8746 16.6684 48.8495 16.5556 48.7868C16.3298 48.6614 16.1919 48.4231 16.1919 48.1723V33.6381L8.93103 28.2833C3.78948 24.4711 0.829956 18.9157 0.829956 13.0468V8.13097C0.829956 7.74222 1.14347 7.42871 1.53222 7.42871H41.2977C41.6864 7.42871 42 7.74222 42 8.13097V13.0468C42 18.9157 39.0404 24.4585 33.8863 28.2708L26.6255 33.6255V42.7549C26.6255 43.0057 26.5001 43.2314 26.2869 43.3568L17.2704 48.7617C17.1575 48.8244 17.0321 48.862 16.9067 48.862V48.8746ZM2.24702 8.83323V13.0468C2.24702 18.4642 4.99335 23.5932 9.78377 27.1422L17.3205 32.7101C17.4961 32.848 17.609 33.0487 17.609 33.2744V46.9183L25.221 42.3536V33.2744C25.221 33.0487 25.3213 32.8355 25.5094 32.7101L33.0461 27.1422C37.8366 23.6058 40.5829 18.4642 40.5829 13.0468V8.83323H2.24702Z" fill="white" />
                    <path d="M9.64586 18.0757C9.25711 18.0757 8.9436 17.7622 8.9436 17.3734V0.757436C8.9436 0.368685 9.25711 0.0551758 9.64586 0.0551758C10.0346 0.0551758 10.3481 0.368685 10.3481 0.757436V17.3609C10.3481 17.7496 10.0346 18.0631 9.64586 18.0631V18.0757Z" fill="#0DA3E2" />
                    <path d="M9.64579 18.0756C9.47022 18.0756 9.28212 18.0129 9.14417 17.8624C8.86828 17.5865 8.86828 17.135 9.14417 16.8592L12.1162 13.8871C12.3921 13.6112 12.8436 13.6112 13.1195 13.8871C13.3954 14.163 13.3954 14.6144 13.1195 14.8903L10.1474 17.8624C10.0095 18.0003 9.83389 18.0756 9.64579 18.0756Z" fill="#0DA3E2" />
                    <path d="M9.64581 18.0756C9.47024 18.0756 9.28213 18.0129 9.14419 17.8624L6.17213 14.8903C5.89624 14.6144 5.89624 14.163 6.17213 13.8871C6.44801 13.6112 6.89947 13.6112 7.17535 13.8871L10.1474 16.8592C10.4233 17.135 10.4233 17.5865 10.1474 17.8624C10.0095 18.0003 9.83391 18.0756 9.64581 18.0756Z" fill="#0DA3E2" />
                    <path d="M21.4213 18.0757C21.0325 18.0757 20.719 17.7622 20.719 17.3734V0.757436C20.719 0.368685 21.0325 0.0551758 21.4213 0.0551758C21.81 0.0551758 22.1235 0.368685 22.1235 0.757436V17.3609C22.1235 17.7496 21.81 18.0631 21.4213 18.0631V18.0757Z" fill="#0DA3E2" />
                    <path d="M21.4212 18.0756C21.2456 18.0756 21.0575 18.0129 20.9196 17.8624C20.6437 17.5865 20.6437 17.135 20.9196 16.8592L23.8916 13.8871C24.1675 13.6112 24.619 13.6112 24.8949 13.8871C25.1707 14.163 25.1707 14.6144 24.8949 14.8903L21.9228 17.8624C21.7848 18.0003 21.6093 18.0756 21.4212 18.0756Z" fill="#0DA3E2" />
                    <path d="M21.4212 18.0756C21.2456 18.0756 21.0575 18.0129 20.9196 17.8624L17.9475 14.8903C17.6716 14.6144 17.6716 14.163 17.9475 13.8871C18.2234 13.6112 18.6749 13.6112 18.9507 13.8871L21.9228 16.8592C22.1987 17.135 22.1987 17.5865 21.9228 17.8624C21.7849 18.0003 21.6093 18.0756 21.4212 18.0756Z" fill="#0DA3E2" />
                    <path d="M33.1966 18.0757C32.8079 18.0757 32.4944 17.7622 32.4944 17.3734V0.757436C32.4944 0.368685 32.8079 0.0551758 33.1966 0.0551758C33.5854 0.0551758 33.8989 0.368685 33.8989 0.757436V17.3609C33.8989 17.7496 33.5854 18.0631 33.1966 18.0631V18.0757Z" fill="#0DA3E2" />
                    <path d="M33.1966 18.0756C33.021 18.0756 32.8329 18.0129 32.695 17.8624C32.4191 17.5865 32.4191 17.135 32.695 16.8592L35.667 13.8871C35.9429 13.6112 36.3944 13.6112 36.6702 13.8871C36.9461 14.163 36.9461 14.6144 36.6702 14.8903L33.6982 17.8624C33.5602 18.0003 33.3847 18.0756 33.1966 18.0756Z" fill="#0DA3E2" />
                    <path d="M33.1966 18.0756C33.021 18.0756 32.8329 18.0129 32.695 17.8624L29.7229 14.8903C29.447 14.6144 29.447 14.163 29.7229 13.8871C29.9988 13.6112 30.4502 13.6112 30.7261 13.8871L33.6982 16.8592C33.9741 17.135 33.9741 17.5865 33.6982 17.8624C33.5603 18.0003 33.3847 18.0756 33.1966 18.0756Z" fill="#0DA3E2" />
                </g>
                <defs>
                    <clipPath id="clip0_2055_494">
                        <rect width="41.17" height="48.8196" fill="white" transform="translate(0.829956 0.0551758)" />
                    </clipPath>
                </defs>
            </svg>

        </SvgIcon>
    );
}
