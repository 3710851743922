import { SvgIcon } from '@mui/material';
import React from 'react';

export default function EmailIcon({ width = 50, height = 50, mb=2}) {
    return (
        <SvgIcon sx={{width, height, mb}}>
            <svg width="24" height="24" viewBox="0 0 42 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_2055_765)">
                    <path d="M20.799 21.0908C20.6293 21.0908 20.4596 21.0193 20.3465 20.8883L17.7105 18.0298C17.4729 17.7678 17.4729 17.3509 17.7218 17.0889C17.9707 16.8388 18.3666 16.8388 18.6155 17.1008L20.7311 19.3995L24.4192 14.278C24.6342 13.9803 25.0301 13.9326 25.3016 14.147C25.5845 14.3733 25.6297 14.7902 25.4261 15.076L21.2968 20.8049C21.1836 20.9598 21.0139 21.055 20.8329 21.0669C20.8216 21.0669 20.8103 21.0669 20.799 21.0669V21.0908Z" fill="#0DA3E2" />
                    <path d="M41.3777 46.3527H1.31774C0.967034 46.3527 0.684204 46.055 0.684204 45.6857V17.1723C0.684204 16.9699 0.77471 16.7674 0.933094 16.6483L20.9574 0.319194C21.1837 0.128628 21.5005 0.128628 21.7267 0.319194L41.7511 16.6483C41.9095 16.7793 42 16.9699 42 17.1723V45.6857C42 46.055 41.7171 46.3527 41.3664 46.3527H41.3777ZM1.95128 45.0307H40.7442V17.5058L21.3421 1.67697L1.95128 17.5058V45.0307Z" fill="#F5F5F5" />
                    <path d="M1.31776 46.3526C1.11413 46.3526 0.921803 46.2454 0.797358 46.0549C0.605033 45.7452 0.672912 45.3402 0.967055 45.1378L41.0158 16.6244C41.3099 16.4219 41.6945 16.4934 41.8869 16.803C42.0792 17.1127 42.0113 17.5176 41.7172 17.7201L1.66847 46.2454C1.55534 46.3169 1.44221 46.3645 1.31776 46.3645V46.3526Z" fill="#F5F5F5" />
                    <path d="M41.3778 46.3526C41.2533 46.3526 41.1289 46.3169 41.0271 46.2335L0.967055 17.7201C0.672912 17.5176 0.605033 17.1008 0.797358 16.803C0.989682 16.4934 1.38564 16.4219 1.66847 16.6244L41.7285 45.1378C42.0226 45.3402 42.0905 45.7571 41.8982 46.0549C41.7737 46.2454 41.5814 46.3526 41.3778 46.3526Z" fill="#F5F5F5" />
                </g>
                <defs>
                    <clipPath id="clip0_2055_765">
                        <rect width="41.3158" height="46.1764" fill="white" transform="translate(0.684204 0.17627)" />
                    </clipPath>
                </defs>
            </svg>
        </SvgIcon>
    );
}
