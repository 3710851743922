import React from 'react';
import ActionNodeTemplate from '../templates/ActionNodeTemplate';
// Or TriggerNodeTemplate, if you want that style
import { Tooltip, Typography } from '@mui/material';

export default function CustomCommandNode({ id, type, data, selected }) {
    return (
        <ActionNodeTemplate id={id} type={type} data={data} selected={selected}>
            {/* For demonstration: display the chosen command in a Tooltip */}
            <Tooltip title={data.command?.label || 'No command chosen'}>
                <Typography noWrap fontSize={8}>
                    Command: {data.command?.label || 'None'}
                </Typography>
            </Tooltip>
        </ActionNodeTemplate>
    );
}
