import React from 'react';
import CustomHandle from '../handles/CustomHandle';
import { useThemeContext } from '../../../Theme/Theme';

const ExitNode = () => {
    const { mode } = useThemeContext();
    return (
        <div className={`node-template ${mode}`} style={{
            minWidth: 60,
            width: 60,
            height: 30,
            minHeight: 30,
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
        }}>
            <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                Exit
            </div>
            <CustomHandle type="target" position="top" style={{ left: "0.1", right: "0.1", background: '#00aaff' }} />
        </div>
    );
};

export default ExitNode;
