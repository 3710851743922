// components/flow/nodes/StayedTimeOnPageNode.js
import { Handle } from '@xyflow/react';
import React from 'react';
import TriggerNodeTemplate from '../templates/TriggerNodeTemplate';
import { Tooltip, Typography } from '@mui/material';

const StayedTimeOnPageNode = ({ id, type, data }) => {
    return (
        <TriggerNodeTemplate id={id} type={type} data={data}>
            {/* Tooltip for Time */}
            <Tooltip title={`Time: ${data.timeAmount} ${data.timeUnit?.label || ''}`}>
                <Typography fontSize={8} noWrap>
                    Time: {data.timeAmount} {data.timeUnit?.label || ''}
                </Typography>
            </Tooltip>

            {/* Tooltip for Send To */}
            <Tooltip title={`Send To: ${data.sendTo || ''}`}>
                <Typography fontSize={8} noWrap>
                    Send To: {data.sendTo || ''}
                </Typography>
            </Tooltip>

            {/* Tooltip for Days to Skip */}
            <Tooltip title={`Days to Skip: ${data.daysToSkip?.map(day => day.label).join(', ') || 'None'}`}>
                <Typography fontSize={8} noWrap>
                    Days to Skip: {data.daysToSkip?.map(day => day.label).join(', ') || 'None'}
                </Typography>
            </Tooltip>

            {/* Source Handle */}
            <Handle type="source" position="bottom" style={{ background: '#555' }} />
        </TriggerNodeTemplate>
    );
};

export default StayedTimeOnPageNode;
