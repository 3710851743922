import React from 'react'
import TriggerNodeTemplate from '../templates/TriggerNodeTemplate'
import { Tooltip, Typography } from '@mui/material'

export default function SQLNode({ id, type, data }) {
    return (
        <TriggerNodeTemplate id={id} type={type} data={data}>
            <Tooltip title={data.query}>
                <Typography noWrap fontSize={8}>
                    Query: {data.query}
                </Typography>
            </Tooltip>
        </TriggerNodeTemplate>
    )
}
