// usetestautomationapi.js
import axios from "axios";
import { transformFormToApiPayload } from "../../utils/transformers/automationTransformers";

// Base URL for the test API
// const baseURL = "http://localhost:64147/Api/V1";
const baseURL = process.env.REACT_APP_API_URL;

// Create an instance of axios with the base URL
const apiClient = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
    InternalSecret: "HardCodedPassword",
  },
});

// API functions

// Automation-related functions

/**
 * Get all automations
 * @returns {Promise<Array>} List of automations
 */
export const getAutomations = async () => {
  try {
    const response = await apiClient.get("/Automation");
    return response.data;
  } catch (error) {
    console.error("Error fetching automations:", error);
    throw error;
  }
};

export const getAutomationsBybrandId = async (brandId) => {
  try {
    const response = await apiClient.get(
      `/Automation/automationsof/${brandId}`
    );

    const data = transformAutomationResponse(response.data);
    return data;
  } catch (error) {
    console.error("Error fetching automations:", error);
    throw error;
  }
};

/**
 * Create a new automation
 * @param {Object} automationData - Data for the new automation
 * @returns {Promise<Object>} Created automation
 */
export const createAutomation = async (automationData, brandId, userId) => {
  try {
    const payload = transformFormToApiPayload(automationData, brandId, userId);
    console.log(payload);
    const response = await apiClient.post("/Automation", payload);
    return response.data;
  } catch (error) {
    console.error("Error creating automation:", error);
    throw error;
  }
};

/**
 * Get an automation by ID
 * @param {number} id - Automation ID
 * @returns {Promise<Object>} Automation data
 */
export const getAutomationById = async (id) => {
  try {
    const response = await apiClient.get(`/Automation/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching automation with ID ${id}:`, error);
    throw error;
  }
};

/**
 * Update an existing automation
 * @param {number} id - Automation ID
 * @param {Object} automationData - Updated automation data
 * @returns {Promise<Object>} Updated automation
 */
export const updateAutomation = async (id, automationData) => {
  try {
    automationData = transformFormToApiPayload(automationData);
    automationData.Id = id;
    const response = await apiClient.put(`/Automation/${id}`, automationData);
    return response.data;
  } catch (error) {
    console.error(`Error updating automation with ID ${id}:`, error);
    throw error;
  }
};

/**
 * Delete an automation by ID
 * @param {number} id - Automation ID
 * @returns {Promise<void>}
 */
export const deleteAutomation = async (id) => {
  try {
    await apiClient.delete(`/Automation/${id}`);
  } catch (error) {
    console.error(`Error deleting automation with ID ${id}:`, error);
    throw error;
  }
};

// Flow-related functions

/**
 * Get flow data by automation ID
 * @param {number} automationId - Automation ID
 * @returns {Promise<Object>} Flow data
 */
export const getFlowByAutomationId = async (automationId) => {
  try {
    const response = await apiClient.get(`/Flow/${automationId}`);
    return {
      automationId: response.data[0].AutomationId,
      nodes: JSON.parse(response.data[0].NodesJson || "[]"),
      edges: JSON.parse(response.data[0].EdgesJson || "[]"),
      viewport: JSON.parse(response.data[0].ViewportJson || "{}"),
    };
  } catch (error) {
    console.error(
      `Error fetching flow for automation ID ${automationId}:`,
      error
    );
    throw error;
  }
};

/**
 * Create a new flow
 * @param {Object} flowData - Data for the new flow
 * @returns {Promise<Object>} Created flow
 */
export const createFlow = async (flowData) => {
  try {
    const payload = {
      AutomationId: flowData.AutomationId,
      NodesJson: JSON.stringify(flowData.nodes || []),
      EdgesJson: JSON.stringify(flowData.edges || []),
      ViewportJson: JSON.stringify(flowData.viewport || {}),
      EntityKey: null,
    };
    console.log("final: ", payload);

    const response = await apiClient.post("/Flow", payload);
    return {
      AutomationId: response.data.AutomationId,
      nodes: JSON.parse(response.data.NodesJson || "[]"),
      edges: JSON.parse(response.data.EdgesJson || "[]"),
      viewport: JSON.parse(response.data.ViewportJson || "{}"),
    };
  } catch (error) {
    console.error("Error creating flow:", error);
    throw error;
  }
};

/**
 * Update an existing flow
 * @param {number} automationId - Automation ID
 * @param {Object} flowData - Updated flow data
 * @returns {Promise<Object>} Updated flow
 */
export const updateFlow = async (automationId, flowData) => {
  try {
    const payload = {
      automationId: automationId,
      nodesJson: JSON.stringify(flowData.nodes || []),
      edgesJson: JSON.stringify(flowData.edges || []),
      viewportJson: JSON.stringify(flowData.viewport || {}),
    };
    const response = await apiClient.put(`/Flow/${automationId}`, payload);
    return {
      automationId: response.data.automationId,
      nodes: JSON.parse(response.data.nodesJson || "[]"),
      edges: JSON.parse(response.data.edgesJson || "[]"),
      viewport: JSON.parse(response.data.viewportJson || "{}"),
    };
  } catch (error) {
    console.error(
      `Error updating flow for automation ID ${automationId}:`,
      error
    );
    throw error;
  }
};

/**
 * Delete a flow by automation ID
 * @param {number} automationId - Automation ID
 * @returns {Promise<void>}
 */
export const deleteFlow = async (automationId) => {
  try {
    await apiClient.delete(`/Flow/${automationId}`);
  } catch (error) {
    console.error(
      `Error deleting flow for automation ID ${automationId}:`,
      error
    );
    throw error;
  }
};

//Value functions

export const getUserData = async () => {
  try {
    const data = await apiClient.get("/Journey/GetUserData");
    return data.data;
  } catch (error) {
    throw error;
  }
};

export const getBrandSegments = async (brandId) => {
  try {
    return await apiClient.get(`/Values/Segments/${brandId}`, {
      brandId: parseInt(brandId),
    });
  } catch (error) {
    throw error;
  }
};

export const getWhatsappNumbers = async (brandId) => {
  try {
    const nums = await apiClient.get(`/Values/WhatsappNumbers/${brandId}`);
    return nums;
  } catch (error) {
    throw error;
  }
};

export const getWhatsappTemplate = async (brandId) => {
  try {
    const templates = await apiClient.post(`/SMS/GetWhatsappTemplates`);
    return templates;
  } catch (error) {
    throw error;
  }
};

export const getSmsTemplates = async (brandId) => {
  try {
    const templates = await apiClient.get(
      `/SMS/GetSmsTemplates?brandId=${brandId}`
    );
    return templates;
  } catch (error) {
    throw error;
  }
};

export const getEmailSources = async (brandId) => {
  try {
    const emails = await apiClient.get(`/Values/Emails/${brandId}`);
    return emails;
  } catch (error) {
    return error;
  }
};

export const getCampaigns = async (brandId) => {
  try {
    const campaigns = await apiClient.get(`/Values/Campaigns/${brandId}`);
    console.log(campaigns);

    return campaigns;
  } catch (error) {
    throw error;
  }
};

// export const tesFilter = async (brandId) => {
//   try {
//     const data = await apiClient.get("/Journey/GetApplicableFlowTest");
//     console.log(data);
//   } catch (error) {
//     throw error;
//   }
// };

const transformAutomationResponse = (apiResponse) => {
  return apiResponse.map((item) => ({
    id: item.Id,
    brandId: item.BrandId,
    name: item.Name,
    description: item.Description,
    status: item.Status,
    priority: item.Priority,
    schedule: {
      start: JSON.parse(item.Schedule).Start,
      end: JSON.parse(item.Schedule).End,
      lastRun: JSON.parse(item.Schedule).LastRun,
      nextRun: JSON.parse(item.Schedule).NextRun,
    },
    metrics: JSON.parse(item.Metrics),
    audiences: JSON.parse(item.Audiences),
    settings: JSON.parse(item.Settings),
    executionsNum: JSON.parse(item.Metrics).Executions || 0,
    successesNum: JSON.parse(item.Metrics).Successes || 0,
    failuresNum: JSON.parse(item.Metrics).Failures || 0,
  }));
};
