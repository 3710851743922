import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ReactFlow,
  Controls,
  MiniMap,
  Background,
  addEdge,
  useNodesState,
  useEdgesState,
  ControlButton,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import AddNodeButton from '../components/flow/AddNodeButton';
import useSelectedNode from "../hooks/flowhooks/useSelectedNode";
import useAutomations from '../hooks/automationhooks/useAutomations';
import { Button, Tooltip } from '@mui/material';
import ROUTES from '../routerfolder/Routes';
import AddNodeContext from '../contexts/AddNodeContext';
import ActionEdge from '../components/flow/edges/ActionEdge';
import useIdGenerator from '../hooks/flowhooks/useIdGenerator';
import useNodeManager from '../hooks/flowhooks/useNodeManager';
import useSave from '../hooks/flowhooks/useSave';
import getNodeTypes from '../utils/workflow/getNodeTypes';
import ConditionEdge from '../components/flow/edges/ConditionEdge';
import { LightMode, DarkMode } from '@mui/icons-material';
import NodeSideMenu from '../components/flow/menus/NodeSideMenu';
import { useThemeContext } from '../Theme/Theme';
import InfoIcon from '@mui/icons-material/Info';


export default function Workflow() {
  const { automationId, brandId } = useParams();
  const { handleGetFlow, handleEditJourneyAutomation, isLoading } = useAutomations();
  const { onNodeClick, onPaneClick, selectedNode } = useSelectedNode();
  const { mode, toggleTheme } = useThemeContext();
  // Side Menu State
  const [sideMenuOpen, setSideMenuOpen] = useState(false);
  const [nodeFormData, setNodeFormData] = useState({});

  const [tempAutomation, setTempAutomation] = useState(null);
  const [rfInstance, setRfInstance] = useState(null);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [colorMode, setColorMode] = useState('dark');
  const generateId = useIdGenerator();
  const { addNodeBetweenNodes, addNode, onNodesDelete, editNode, cleanupOrphanedExitNodes } = useNodeManager(nodes, edges, setNodes, setEdges, generateId);
  const navigate = useNavigate();
  const nodeTypes = getNodeTypes();
  const initialized = useRef(false);
  const edgeTypes = {
    actionEdge: ActionEdge,
    conditionEdge: ConditionEdge
  };

  const onSave = useSave(rfInstance, tempAutomation, automationId, handleEditJourneyAutomation);

  useEffect(() => {
    if (selectedNode) {
      // Populate form data with node info
      setNodeFormData({ label: selectedNode.data?.label || '', description: selectedNode.data?.description || '' });
      setSideMenuOpen(true);
    } else {
      setSideMenuOpen(false);
    }
  }, [selectedNode]);

  useEffect(() => {
    const loadAutomation = async () => {
      try {
        const targetAutomation = await handleGetFlow(automationId);
        if (!targetAutomation) {
          console.error('Automation not found');
          navigate(ROUTES.ROOT);
          return;
        }

        setTempAutomation(targetAutomation);

        if (targetAutomation && !initialized.current) {
          setNodes(targetAutomation.nodes || []);
          setEdges(targetAutomation.edges || []);
          initialized.current = true;
        }
      } catch (error) {
        console.error('Failed to load automation:', error);
      }
    };

    loadAutomation();
  }, []);

  const onEdgesDelete = useCallback(
    (deletedEdges) => {
      setEdges((currentEdges) => {
        const updatedEdges = currentEdges.filter(
          (edge) => !deletedEdges.some((deletedEdge) => deletedEdge.id === edge.id)
        );
        return updatedEdges;
      });
      setTimeout(() => {
        cleanupOrphanedExitNodes();
      }, 0);
    },
    [setEdges, cleanupOrphanedExitNodes]
  );

  const onConnect = useCallback(
    (params) => {
      const { sourceHandle, source } = params;
      const sourceNode = nodes.find(node => node.id === source);
      const isConditionNode = sourceNode?.type === 'conditionNode';

      let edgeData = {
        ...params,
        type: isConditionNode ? 'conditionEdge' : 'actionEdge',
        data: {}
      };

      if (isConditionNode) {
        let label = '';
        if (sourceHandle === 'yes') {
          label = 'Yes';
        } else if (sourceHandle === 'no') {
          label = 'No';
        }
        edgeData.data.label = label;
      }

      setEdges((eds) => addEdge(edgeData, eds));
    },
    [setEdges, nodes]
  );

  const toggleColorMode = useCallback(() => {
    setColorMode(prevMode => prevMode === 'dark' ? 'light' : 'dark');
    toggleTheme();
  }, []);

  if (isLoading || !tempAutomation) {
    return <div>Loading...</div>;
  }

  const handleSideMenuSave = (formData) => {
    if (selectedNode) {
      editNode(selectedNode.id, formData);
      formData = {};
    }
    setSideMenuOpen(false);
  };

  const handleSideMenuCancel = () => {
    setNodeFormData({});
    setSideMenuOpen(false);
  };

  return (
    <AddNodeContext.Provider value={{ addNodeBetweenNodes, addNode, editNode }}>
      <div style={{ width: '100vw', height: '100vh', position: 'relative' }}>

        <Button
          style={{ position: 'absolute', top: 10, right: 10, zIndex: 1 }}
          onClick={onSave}
          variant="contained"
          sx={{ backgroundColor: "#0DA3E2" }}
        >
          Save {tempAutomation.Name}
        </Button>
        <Button
          style={{ position: 'absolute', top: 10, left: 10, zIndex: 1 }}
          onClick={() => navigate(`/journeys/${brandId}`)}
          variant="contained"
          sx={{ backgroundColor: "#0DA3E2" }}
        >
          Go Back
        </Button>

        <div
          style={{
            position: 'absolute',
            top: 60,   // 10 + 50-ish
            left: 10,
            zIndex: 1
          }}
        >
          <Tooltip title={<>
            Click and drag nodes to move them.
            <br />
            Click and drag the background to pan.
            <br />
            Click and drag while holding shift to select multiple nodes.
            <br />
            Press control while scrolling to zoom in and out.
            <br />
            Press backspace while a node is selected to delete it.
          </>}>
            <Button
              variant="contained"
              style={{ borderRadius: 28, minWidth: 'auto', aspectRatio: '1 / 1' }}
              sx={{ backgroundColor: '#0DA3E2' }}
            >
              <InfoIcon />
            </Button>
          </Tooltip>
        </div>

        <AddNodeButton nodesExist={nodes.length > 0} />

        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onEdgesDelete={onEdgesDelete}
          onNodesDelete={onNodesDelete}
          colorMode={colorMode}
          onConnect={onConnect}
          onInit={setRfInstance}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          onNodeClick={onNodeClick}
          onPaneClick={onPaneClick}
          panOnScroll={true}
          fitView
        >
          <Controls orientation="horizontal">
            <ControlButton onClick={toggleColorMode} title={`Switch to ${colorMode === 'dark' ? 'light' : 'dark'} mode`}>
              {colorMode === 'dark' ? <LightMode /> : <DarkMode />}
            </ControlButton>
          </Controls>
          <MiniMap />
          <Background variant="dots" gap={12} size={1} />
        </ReactFlow>

        {/* Use the extracted NodeSideMenu component */}
        <NodeSideMenu
          open={sideMenuOpen}
          selectedNode={selectedNode}
          onSave={handleSideMenuSave}
          onCancel={handleSideMenuCancel}
          setMenu={setSideMenuOpen}
        />
      </div>
    </AddNodeContext.Provider>
  );
}
