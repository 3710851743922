import "./App.css";
import Router from "./routerfolder/Router";
import Theme from "./Theme/Theme";

function App() {
  return (
    <Theme>
      <Router />
    </Theme>
  );
}

export default App;
