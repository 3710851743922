import React, { useEffect, useState } from 'react';
import FormTemplate from '../../../forms/FormTemplate';
import { Box, Button, Stack, Typography } from '@mui/material';
import "../../../styles/SMSSideMenu.css"
import SMSTemplateSelection from './SMSTemplateSelection';
import useAutomations from '../../../hooks/automationhooks/useAutomations';
import { useParams } from 'react-router-dom';

export default function SMSSideMenu({ onClose, onSubmit }) {
    const { isLoading, smsTemplates, handleGetSmsTemplates } = useAutomations()
    const { brandId } = useParams();
    const [selected, setSelected] = useState();
    const [templateSelectionOpen, setTemplateSelectionOpen] = useState(false);
    const [valid, setValid] = useState(false);

    const handleSelected = (key) => {
        setSelected(key);
        setValid(true);
        setTemplateSelectionOpen(false)
    };

    const handleOpenSelection = () => {
        setTemplateSelectionOpen(true);
    };

    const handleClose = () => {
        setTemplateSelectionOpen(false);
    };

    useEffect(() => {
        handleGetSmsTemplates(brandId);
    }, [])

    return (
        <>
            {!isLoading ?
                <>
                    <FormTemplate title={"Edit Whatsapp"} isValid={valid} onClose={onClose} handleSubmit={onSubmit(selected)}>
                        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                            <Stack spacing={2} sx={{ flexShrink: 0, marginBottom: 2 }}>
                                <Button variant="contained" color="companyBlue" onClick={handleOpenSelection}>
                                    Select Template
                                </Button>
                                <Button variant="contained" color="companyGreen">
                                    Create New Template
                                </Button>
                            </Stack>
                            {
                                selected ? (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            padding: 2,
                                            backgroundColor: "black",
                                            borderRadius: 2,
                                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                                            position: "relative",
                                            transition: "box-shadow 0.3s ease-in-out",
                                            "&:hover": {
                                                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.3)", // Shadow on hover
                                            },
                                        }}
                                    >
                                        {/* Tooltip and Message Text */}
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                width: "100%",
                                                textAlign: "center",
                                            }}
                                        >
                                            {selected.Content}
                                        </Typography>
                                        {/* Item Bar */}
                                    </Box>
                                ) : (
                                    <Typography align='center'>No Template Selected!</Typography>
                                )
                            }
                        </Box >
                    </FormTemplate>
                    <SMSTemplateSelection open={templateSelectionOpen} setSelected={handleSelected} setClosed={handleClose} templates={smsTemplates.data} />
                </>
                : <Typography>Loading Templates...</Typography>}
        </>
    );
}
