import React from 'react'
import ActionNodeTemplate from '../templates/ActionNodeTemplate'
import { Tooltip, Typography } from '@mui/material'

export default function EmailMessageNode({ id, type, data, selected }) {
    return (
        <ActionNodeTemplate id={id} type={type} data={data} selected={selected}>
            {/* Wrap "Send From" in a Tooltip */}
            <Tooltip title={data.sendFrom.label} placement="top">
                <Typography noWrap fontSize={8}>
                    Send From: {data.sendFrom.label}
                </Typography>
            </Tooltip>

            {/* Wrap "Subject" in a Tooltip */}
            <Tooltip title={data.subject}>
                <Typography noWrap fontSize={8}>
                    Subject: {data.subject}
                </Typography>
            </Tooltip>

            {/* Wrap "Template" in a Tooltip */}
            <Tooltip title={data.template.label}>
                <Typography noWrap fontSize={8}>
                    Template: {data.template.label}
                </Typography>
            </Tooltip>
        </ActionNodeTemplate>
    )
}
