import { SvgIcon } from '@mui/material';
import React from 'react';

export default function WhatsappIcon({ width = 50, height = 50, mb=2}) {
    return (
        <SvgIcon sx={{width,height, mb}}>
            <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_2055_824)">
                    <path d="M26.76 0.5C12.47 0.5 0.849976 12.12 0.849976 26.4C0.849976 31.81 2.50998 36.84 5.36998 41C5.59998 41.34 5.83998 41.68 6.08998 42.01L6.51998 40.92L5.79998 39.87C3.28998 35.99 1.83998 31.36 1.83998 26.4C1.83998 12.66 13.02 1.48 26.76 1.48C40.5 1.48 51.68 12.66 51.68 26.4C51.68 40.14 40.5 51.33 26.76 51.33C22.58 51.33 18.64 50.3 15.18 48.47C14.74 48.24 14.31 48 13.89 47.74L12.65 48.11C13.05 48.38 13.45 48.63 13.87 48.86C17.66 51.06 22.07 52.31 26.76 52.31C41.04 52.31 52.67 40.69 52.67 26.4C52.67 12.11 41.04 0.5 26.76 0.5Z" fill="#0DA3E2" stroke="#0DA3E2" stroke-miterlimit="10" />
                    <path d="M15.18 48.4701L13.87 48.8601L0.829956 52.7801L5.36996 41.0001L5.79996 39.8701L6.51996 40.9201L6.08996 42.0101L2.50996 51.1601L12.65 48.1101L13.89 47.7401C14.31 48.0001 14.74 48.2401 15.18 48.4701Z" fill="#F15A24" stroke="#0DA3E2" stroke-miterlimit="10" />
                    <path d="M39.65 33.22C39.58 34.33 39.41 35.23 38.98 36.05C38.77 36.44 38.47 36.79 38.13 37.08C37.18 37.91 36.09 38.47 34.85 38.75C33.35 39.09 31.92 38.86 30.53 38.31C29.14 37.75 27.73 37.22 26.37 36.6C24.26 35.63 22.47 34.2 20.83 32.57C18.89 30.65 17.32 28.45 15.89 26.15C14.99 24.7 14.27 23.18 13.99 21.49C13.59 19.1 14.15 16.97 15.81 15.16C15.89 15.07 15.98 15 16.05 14.91C16.74 14.09 17.61 13.77 18.68 13.95C18.96 14 19.25 14 19.54 14C19.9 14 20.19 14.15 20.36 14.47C20.52 14.76 20.66 15.06 20.78 15.37C21.38 16.96 21.97 18.55 22.57 20.14C22.71 20.5 22.66 20.83 22.49 21.17C22.07 22 21.42 22.63 20.78 23.28C20.26 23.81 20.2 24.09 20.56 24.74C22.16 27.63 24.29 30.02 27.18 31.68C27.86 32.07 28.56 32.41 29.27 32.74C29.77 32.98 30.11 32.9 30.48 32.49C31.2 31.68 31.92 30.86 32.63 30.03C32.98 29.62 33.23 29.5 33.74 29.68C34.23 29.86 34.7 30.09 35.16 30.33C36.48 31.02 37.79 31.74 39.11 32.44C39.5 32.65 39.73 32.93 39.67 33.21L39.65 33.22Z" fill="white" />
                </g>
                <defs>
                    <clipPath id="clip0_2055_824">
                        <rect width="53.17" height="53.55" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </SvgIcon>
    );
}
