import { SvgIcon } from '@mui/material';
import React from 'react';

export default function SMSIcon({ width = 50, height = 50, mb=2}) {
    return (
        <SvgIcon sx={{width,height, mb}}>
            <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_2055_811)">
                    <path d="M31.2128 49.0001C22.0572 49.0001 14.2638 42.1138 13.491 33.3196C13.4779 33.0921 13.5565 32.8773 13.7399 32.7257C13.9233 32.5741 14.1459 32.5109 14.3817 32.5614C15.495 32.7889 16.6477 32.9026 17.8003 32.9026C26.8118 32.9026 34.1336 25.8394 34.1336 17.1463C34.1336 16.6661 34.1074 16.2113 34.0681 15.7943C34.0419 15.5669 34.1336 15.3521 34.3039 15.2004C34.4742 15.0488 34.7099 14.9856 34.9457 15.0362C43.0927 16.704 49.0131 23.7672 49.0131 31.816C49.0131 35.1391 48.0307 38.3485 46.1708 41.1282L48.1093 47.029C48.201 47.2943 48.1093 47.5723 47.9128 47.7618C47.7033 47.9513 47.402 48.0019 47.14 47.9008L41.5734 45.7781C38.5346 47.8755 34.9588 48.9874 31.2128 48.9874V49.0001ZM15.0497 34.1282C16.2023 41.7474 23.1181 47.5849 31.1997 47.5849C34.7754 47.5849 38.1678 46.4983 41.0232 44.4261C41.2328 44.2744 41.4948 44.2492 41.7305 44.3376L46.2363 46.056L44.6514 41.242C44.5859 41.0398 44.6121 40.8124 44.7431 40.6355C46.5637 38.0326 47.533 34.9874 47.533 31.8286C47.533 24.7528 42.5557 18.5109 35.5744 16.6409C35.5744 16.8051 35.5744 16.982 35.5744 17.1589C35.5744 26.6228 27.5846 34.3178 17.7741 34.3178C16.8572 34.3178 15.9404 34.2546 15.0366 34.1156L15.0497 34.1282Z" fill="#0DA3E2" />
                    <path d="M17.7872 34.3303C16.5298 34.3303 15.2724 34.204 14.0543 33.9513C11.6442 33.4585 9.41753 32.5108 7.42662 31.1209L1.87303 33.2563C1.61107 33.3574 1.30981 33.3069 1.10024 33.1173C0.890671 32.9278 0.812082 32.6498 0.903769 32.3845L2.84229 26.4838C0.982358 23.704 0 20.4946 0 17.1715C0 7.70758 7.97674 0 17.7872 0C26.9559 0 34.7362 6.88628 35.509 15.6805C35.5613 16.1354 35.5744 16.6408 35.5744 17.1588C35.5744 26.6227 27.5846 34.3177 17.7741 34.3177L17.7872 34.3303ZM7.54451 29.6173C7.70168 29.6173 7.85886 29.6679 7.98984 29.7563C9.88907 31.1462 12.0372 32.0939 14.3817 32.574C15.4951 32.8014 16.6477 32.9152 17.8003 32.9152C26.8118 32.9152 34.1337 25.852 34.1337 17.1588C34.1337 16.6787 34.1075 16.2238 34.0682 15.8069C33.3478 7.73285 26.2093 1.40253 17.8003 1.40253C8.78883 1.41516 1.46699 8.47834 1.46699 17.1715C1.46699 20.3303 2.43625 23.3755 4.25688 25.991C4.38786 26.1679 4.41406 26.3953 4.34857 26.5975L2.7637 31.4116L7.26945 29.6805C7.36113 29.6426 7.45282 29.63 7.54451 29.63V29.6173Z" fill="white" />
                </g>
                <defs>
                    <clipPath id="clip0_2055_811">
                        <rect width="49" height="49" fill="white" />
                    </clipPath>
                </defs>
            </svg>

        </SvgIcon>
    );
}
