// components/flow/nodes/NewLeadNode.js
import React from 'react';
import TriggerNodeTemplate from '../templates/TriggerNodeTemplate';
import { Tooltip, Typography } from '@mui/material';

const NewLeadNode = ({ id, type, data }) => {
    const campaigns = data.campaignSource
        ?.map((campaign) => campaign.label) // Extract the label of each campaign
        .join(', '); // Join the labels with a comma
    const types = data.leadType?.map((lead) => lead.label).join(',');

    return (
        <TriggerNodeTemplate id={id} type={type} data={data}>
            <Tooltip title={campaigns} placement='top'>
                <Typography sx={{ mt: 0.5 }} fontSize={8} noWrap>Campaign(s): {campaigns || 'None'}</Typography> {/* Display 'None' if no campaigns */}
            </Tooltip>
            <Tooltip title={types} placement='bottom'>
                <Typography fontSize={8} noWrap>Lead Type: {types || 'N/A'}</Typography> {/* Safely access leadType */}
            </Tooltip>
        </TriggerNodeTemplate>
    );
};

export default NewLeadNode;
