// getNodeFormFields.js
export function getNodeFormFields(
  type,
  nodeTypesConfig,
  brandSegments,
  campaigns,
  whatsappNumbers,
  emails
) {
  const { triggers, actions } = nodeTypesConfig;
  const allNodes = [...triggers, ...actions];
  const nodeTypeConfig = allNodes.find((node) => node.type === type);

  if (!nodeTypeConfig || !nodeTypeConfig.formFields) {
    return null;
  }

  const formFields = nodeTypeConfig.formFields.map((field) => {
    let updatedField = { ...field };

    // Handle brand segments for 'addedToSegment'
    if (type === "addedToSegment" && field.id === "segment") {
      updatedField = {
        ...field,
        options:
          brandSegments?.data?.map((segment) => ({
            value: segment.Id,
            label: segment.Name,
          })) || [],
      };
    }

    // Handle campaigns for 'newLead'
    if (type === "newLead" && field.id === "campaignSource") {
      updatedField = {
        ...field,
        options:
          campaigns?.data?.map((camp) => ({
            value: camp.Id,
            label: camp.Name,
          })) || [],
      };
    }

    if (type === "whatsappMessage" && field.id === "sendFrom") {
      updatedField = {
        ...field,
        options:
          whatsappNumbers?.data?.map((phone) => ({
            value: phone,
            label: phone,
          })) || [],
      };
    }

    return updatedField;
  });

  return formFields;
}
