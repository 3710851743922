import React from 'react'
import TriggerNodeTemplate from '../templates/TriggerNodeTemplate'
import { Tooltip, Typography } from '@mui/material'

export default function AddedToSegment({ id, type, data, selected }) {
  return (
    <TriggerNodeTemplate id={id} type={type} data={data} selected={selected}>
      <Tooltip title={data.segment ?
        data.segment.map(segment => segment.label).join(', ') :
        'No segments selected'}>
        <Typography fontSize={8} noWrap>
          Segments: {data.segment ?
            data.segment.map(segment => segment.label).join(', ') :
            'No segments selected'}
        </Typography>
      </Tooltip>
    </TriggerNodeTemplate>
  )
}
