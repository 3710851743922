import React, { useState, useContext } from 'react';
import { Handle } from '@xyflow/react';
import { IconButton, Typography, Box, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import NodesDynamicForm from '../../../../forms/NodesDynamicForm';
import nodeTypesConfig, { conditionTypes } from '../../../../models/nodeTypesConfig';
import AddNodeContext from '../../../../contexts/AddNodeContext';
import NodeConfigModal from '../../menus/NodeConfigModal';
import { useThemeContext } from '../../../../Theme/Theme';
import '../../../../styles/NodeTemplate.css';

const ConditionNode = ({ id, type, data, selected }) => {
    const [configModal, setConfigModal] = useState({ open: false });
    const { editNode } = useContext(AddNodeContext);
    const { mode } = useThemeContext();

    const handleEdit = () => {
        const nodeConfig = nodeTypesConfig.conditions.find(
            (node) => node.type === type
        );

        setConfigModal({
            open: true,
            content: (
                <NodesDynamicForm
                    title={`Edit "${nodeConfig?.displayName}" Node`}
                    formFields={nodeConfig?.formFields || []}
                    initialData={data}
                    onSubmit={(formData) => {
                        editNode(id, formData);
                        setConfigModal({ open: false });
                    }}
                />
            ),
        });
    };

    // Helper function to format individual conditions
    const formatCondition = (condition) => {
        if (!condition?.type || !condition?.value) return '';
        const conditionType = conditionTypes[condition.type];
        if (!conditionType) return '';
        const option = conditionType.options.find(opt => opt.value === condition.value);
        const actionType = condition.isWhitelist ? 'IS' : 'IS NOT';
        return `${actionType} ${option?.label || condition.value}`;
    };

    // Join all conditions into a single string (with operator)
    const formatConditions = () => {
        if (!Array.isArray(data.conditions) || data.conditions.length === 0) {
            return 'No conditions set';
        }
        return data.conditions
            .map((cond, idx) => {
                const formatted = formatCondition(cond);
                return idx === 0 ? formatted : ` ${cond.operator} ${formatted}`;
            })
            .join('');
    };

    // Look up icon from config
    const nodeConfig = nodeTypesConfig.actions.find((node) => node.type === type);
    const IconComponent = nodeConfig?.icon || null;
    const iconColor = nodeConfig?.iconColor || '#e0e0e0'; // fallback color

    return (
        <>
            <div
                className={`node-template ${mode}`}
                style={{
                    minWidth: 170,
                    maxWidth: 170,
                    width: '100%',
                    minHeight: 60,
                    maxHeight: 60,
                    height: '100%',
                    borderRadius: 10,
                }}
            >
                {/* We use a Box to mimic the same structure as TriggerNode/ActionNode */}
                <Box sx={{ marginLeft: -1, marginTop: 0.3, marginRight: -1 }}>
                    {/* Header (label + edit icon) */}
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderBottom: '1px solid #444',
                            width: '100%',
                            marginTop: -18,
                        }}
                    >
                        <Tooltip title="Condition Node" placement="top">
                            <Typography
                                style={{ color: '#00aaff', fontSize: 8 }}
                                noWrap
                            >
                                Condition Node
                            </Typography>
                        </Tooltip>
                        <IconButton size="small" onClick={handleEdit}>
                            <EditIcon sx={{ color: '#aaa', fontSize: 10 }} />
                        </IconButton>
                    </div>

                    {/* Condition text + icon row */}
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        {/* The conditions string in a Tooltip for longer text */}
                        <Tooltip title={formatConditions()}>
                            <Typography
                                noWrap
                                fontSize={8}
                                sx={{
                                    flex: 1,
                                    lineHeight: '1.4',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                            >
                                {formatConditions()}
                            </Typography>
                        </Tooltip>

                        {/* Icon */}
                        {IconComponent && (
                            <Box
                                sx={{
                                    backgroundColor: iconColor,
                                    borderRadius: '50%',
                                    width: 18,
                                    height: 18,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    flexShrink: 0,
                                    mt: 0.5,
                                    color: 'white',
                                    position: 'relative',
                                    lineHeight: 0,
                                }}
                            >
                                <IconComponent
                                    sx={{ width: '65%', height: '75%' }}
                                    width={13}
                                    height={13}
                                    mb={0.1}
                                />
                            </Box>
                        )}
                    </div>
                </Box>

                {/* React Flow Handles */}
                <Handle
                    type="target"
                    position="top"
                    style={{ background: '#00aaff' }}
                />
                <Handle
                    type="source"
                    position="bottom"
                    id="yes"
                    style={{ left: 40, background: '#00aaff' }}
                />
                <Handle
                    type="source"
                    position="bottom"
                    id="no"
                    style={{ right: 40, background: '#00aaff' }}
                />
            </div>

            {configModal.open && (
                <NodeConfigModal
                    open={configModal.open}
                    onClose={() => setConfigModal({ open: false })}
                >
                    {configModal.content}
                </NodeConfigModal>
            )}
        </>
    );
};

export default ConditionNode;
