import React from 'react';
import ActionNodeTemplate from '../templates/ActionNodeTemplate';
import { Tooltip, Typography } from '@mui/material';

export default function WaitNode({ id, type, data }) {
    // Safely build the string of days to skip, using the label from each object
    const daysToSkipStr = data.daysToSkip && data.daysToSkip.length
        ? data.daysToSkip.map(day => day.label).join(', ')
        : 'No days to skip';

    return (
        <ActionNodeTemplate id={id} type={type} data={data}>
            {/* Wrap time in a Tooltip */}
            <Tooltip
                // e.g. "7 hours"
                title={`${data.timeAmount} ${data.timeUnit.label}`}
                placement="top"
            >
                <Typography noWrap fontSize={8}>
                    Time: {data.timeAmount} {data.timeUnit.label}
                </Typography>
            </Tooltip>

            {/* Wrap days to skip in a Tooltip */}
            <Tooltip title={daysToSkipStr}>
                <Typography noWrap fontSize={8}>
                    Days to Skip: {daysToSkipStr}
                </Typography>
            </Tooltip>

            {/* If you need hoursToSkip as well: */}
            {data.hoursToSkip && data.hoursToSkip.length > 0 && (
                <Tooltip title={data.hoursToSkip.join(', ')}>
                    <Typography noWrap fontSize={8}>
                        Hours to Skip: {data.hoursToSkip.join(', ')}
                    </Typography>
                </Tooltip>
            )}
        </ActionNodeTemplate>
    );
}
