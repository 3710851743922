import React from 'react'
import ActionNodeTemplate from '../templates/ActionNodeTemplate'
import { Tooltip, Typography } from '@mui/material'

export default function WhatsAppMessageNode({ id, type, data, selected }) {
    return (
        <ActionNodeTemplate id={id} type={type} data={data} selected={selected}>
            <Tooltip title={data.template.label}>
                <Typography noWrap fontSize={8}>Template: {data.template.label}</Typography>
            </Tooltip>
        </ActionNodeTemplate>
    )
} 