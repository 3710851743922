import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import nodeTypesConfig from '../../../../models/nodeTypesConfig';
import AddNodeContext from '../../../../contexts/AddNodeContext';
import NodeConfigModal from '../../menus/NodeConfigModal';
import CustomHandle from '../../handles/CustomHandle';
import '../../../../styles/NodeTemplate.css';
import { useThemeContext } from '../../../../Theme/Theme';

const ActionNodeTemplate = ({ id, type, data, selected, children }) => {
    const [configModal, setConfigModal] = useState({ open: false });
    const { editNode } = useContext(AddNodeContext);
    const { mode } = useThemeContext();

    // Get the icon component from config
    const nodeConfig = nodeTypesConfig.actions.find(node => node.type === type);
    const IconComponent = nodeConfig?.icon;
    const iconColor = nodeConfig?.iconColor || '#e0e0e0'; // Default gray if no color specified

    return (
        <>
            {/* Wrapper div with identical styles to TriggerNodeTemplate */}
            <div
                className={`node-template ${mode}`}
                style={{
                    minWidth: 170,
                    maxWidth: 170,
                    width: '100%',
                    minHeight: 60,
                    maxHeight: 60,
                    height: '100%',
                    borderRadius: 10,
                }}
            >
                <Box
                    sx={{
                        marginLeft: -1,
                        marginTop: 0.3,
                        marginRight: -1,
                    }}
                >
                    {/* Top header with label and edit icon */}
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderBottom: '1px solid #444',
                            width: '100%',
                            marginTop: -18,
                        }}
                    >
                        <strong style={{ color: '#00aaff', fontSize: 8 }}>
                            {data.label}
                        </strong>
                        <IconButton size="small">
                            <EditIcon sx={{ color: '#aaa', fontSize: 10 }} />
                        </IconButton>
                    </div>

                    {/* Flexbox container for children and icon */}
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        {/* Children */}
                        <div
                            style={{
                                flex: 1,
                                fontSize: '0.9rem',
                                lineHeight: '1.4',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {children}
                        </div>

                        {/* Icon */}
                        {IconComponent && (
                            <Box
                                sx={{
                                    backgroundColor: iconColor,
                                    borderRadius: '50%',
                                    width: 18,
                                    height: 18,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    flexShrink: 0,
                                    mt: 0.5,
                                    color: 'white',
                                    position: 'relative',
                                    lineHeight: 0,
                                }}
                            >
                                <IconComponent
                                    sx={{ width: '65%', height: '75%' }}
                                    width={13}
                                    height={13}
                                    mb={0.1}
                                />
                            </Box>
                        )}
                    </div>
                </Box>

                {/* Top and bottom handles */}
                <CustomHandle
                    type="target"
                    position="top"
                    style={{ background: '#00aaff' }}
                />
                <CustomHandle
                    type="source"
                    position="bottom"
                    style={{ background: '#00aaff' }}
                />
            </div>

            {configModal.open && (
                <NodeConfigModal
                    open={configModal.open}
                    onClose={() => setConfigModal({ open: false })}
                >
                    {configModal.content}
                </NodeConfigModal>
            )}
        </>
    );
};

ActionNodeTemplate.propTypes = {
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    selected: PropTypes.bool,
    children: PropTypes.node,
};

export default ActionNodeTemplate;
