import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Dialog,
    DialogTitle,
    Stack,
    TextField,
    Typography,
    Tooltip,
    ImageListItemBar,
} from "@mui/material";

export default function WhatsappTemplateSelection({ open, setSelected, setClosed, templates }) {
    const [temp, setTemp] = useState();
    const [filteredItems, setFilteredItems] = useState(templates);
    const [filters, setFilters] = useState({
        TemplateId: "",
        TemplateName: "",
        MessageText: "",
    });

    const handleFilterChange = (key, value) => {
        setFilters((prev) => ({ ...prev, [key]: value }));
    };

    useEffect(() => {
        setFilteredItems(
            templates.filter((template) =>
                template.TemplateId.toString().includes(filters.TemplateId) &&
                template.TemplateName.toLowerCase().includes(filters.TemplateName.toLowerCase()) &&
                template.MessageText.toLowerCase().includes(filters.MessageText.toLowerCase())
            )
        );
    }, [filters, templates]);

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth="lg"
            sx={{
                "& .MuiDialog-paper": {
                    height: "90vh", // Set dialog height to fit most of the viewport
                    display: "flex",
                    flexDirection: "column",
                },
            }}
        >
            {/* Sticky Filter Section */}
            <Box
                sx={{
                    position: "sticky",
                    top: 0,
                    zIndex: 10,
                    borderBottom: "1px solid #ccc",
                    padding: 2,
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <Stack direction="row" spacing={2} alignItems="center">
                    <TextField
                        label="Id"
                        size="small"
                        variant="outlined"
                        value={filters.TemplateId}
                        onChange={(e) => handleFilterChange("TemplateId", e.target.value)}
                    />
                    <TextField
                        label="Name"
                        size="small"
                        variant="outlined"
                        value={filters.TemplateName}
                        onChange={(e) => handleFilterChange("TemplateName", e.target.value)}
                    />
                    <TextField
                        label="Text"
                        size="small"
                        variant="outlined"
                        value={filters.MessageText}
                        onChange={(e) => handleFilterChange("MessageText", e.target.value)}
                    />
                </Stack>
            </Box>

            {/* Scrollable Content */}
            <Box sx={{
                flexGrow: 1, overflowY: "auto", padding: 2, display: "grid",
                width: '100%', gridTemplateColumns: "repeat(2, 1fr)", gap: 8
            }}>
                {filteredItems.map((item) => (
                    <Box
                        key={item.TemplateId}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: 2,
                            backgroundColor: "black",
                            borderRadius: 2,
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                            minHeight: 400,
                            maxHeight: 400,
                            minWidth: 400,
                            maxWidth: 400,
                            position: "relative",
                            transition: "box-shadow 0.3s ease-in-out",
                            "&:hover": {
                                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.3)", // Shadow on hover
                            },
                        }}
                    >
                        {/* Tooltip and Message Text */}
                        <Tooltip title={item.MessageText} placement="top">
                            <Typography
                                variant="body2"
                                sx={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    width: "100%",
                                    textAlign: "center",
                                }}
                            >
                                {item.MessageText}
                            </Typography>
                        </Tooltip>

                        {/* Item Bar */}
                        <ImageListItemBar
                            title={`${item.TemplateName}, ${item.TemplateId}`}
                            sx={{
                                bgcolor: temp === item ? "green" : "#0DA3E2",
                            }}
                            actionIcon={
                                <Button
                                    variant="contained"
                                    sx={{ mr: 1 }}
                                    onClick={() => setTemp(item)}
                                >
                                    Select
                                </Button>
                            }
                        />
                    </Box>
                ))}
            </Box>

            {/* Footer Buttons */}
            <Box sx={{ display: "flex", justifyContent: "space-evenly", padding: 2 }}>
                <Button
                    variant="contained"
                    color="companyBlue"
                    onClick={() => setSelected(temp)}
                >
                    Select
                </Button>
                <Button variant="contained" color="companyBlue" onClick={setClosed}>
                    Cancel
                </Button>
            </Box>
        </Dialog>
    );
}
