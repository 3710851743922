import React, { createContext, useContext, useMemo, useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Create a context to manage the theme
const ThemeContext = createContext();

export function useThemeContext() {
  return useContext(ThemeContext);
}

export default function CustomThemeProvider({ children }) {
  const [mode, setMode] = useState("dark"); // Default mode is light
  const toggleTheme = () => {
    setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
  };

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode, // Dynamically set mode (light or dark)
          ...(mode === "light"
            ? {
              background: {
                default: "#f5f5f5", // Light background
                paper: "#ffffff", // Light card/paper background
              },
              text: {
                primary: "#000000", // Black text
                secondary: "#555555", // Grey secondary text
              },
            }
            : {
              background: {
                default: "#121212", // Dark background
                paper: "#1e1e1e", // Dark card/paper background
              },
              text: {
                primary: "#ffffff", // White text
                secondary: "#bbbbbb", // Light grey secondary text
              },
            }),
          companyBlue: createTheme().palette.augmentColor({
            color: {
              main: "#0DA3E2", // Company Blue
            },
          }),
          companyGreen: createTheme().palette.augmentColor({
            color: {
              main: "#88BC20", // Company Green
            },
          }),
        },
        typography: {
          fontFamily: ['"Segoe UI"', "Helvetica", "Arial", "sans-serif"].join(
            ","
          ),
        },
        components: {
          MuiCssBaseline: {
            styleOverrides: {
              "@global": {
                body: {
                  color: mode === "light" ? "#000000" : "#ffffff",
                  backgroundColor: mode === "light" ? "#f5f5f5" : "#121212",
                },
              },
            },
          },
        },
      }),
    [mode]
  );

  return (
    <ThemeContext.Provider value={{ mode, toggleTheme }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
}
