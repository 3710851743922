import React, { useContext, useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid2,
  Box,
  Tooltip,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import nodeTypesConfig from '../../models/nodeTypesConfig';
import NodeConfigModal from './menus/NodeConfigModal';
import NodesDynamicForm from "../../forms/NodesDynamicForm"
import AddNodeContext from '../../contexts/AddNodeContext';
import useAutomations from '../../hooks/automationhooks/useAutomations';
import { useParams } from 'react-router-dom';
import { getNodeFormFields } from '../../utils/workflow/getNodeFormFields';

const AddNodeButton = ({ nodesExist }) => {
  const { addNode } = useContext(AddNodeContext)
  const { brandSegments, campaigns, handleGetCampaigns, handleGetBrandSegments } = useAutomations();
  const { automationId, brandId } = useParams();
  const [optionsModalOpen, setOptionsModalOpen] = useState(false);
  const [configModal, setConfigModal] = useState({
    open: false,
    content: null,
  });

  useEffect(() => {
    const currentBrandId = brandId || localStorage.getItem('brandId');
    if (currentBrandId) {
      handleGetCampaigns(currentBrandId);
    }
  }, [brandId]);


  useEffect(() => {
    const currentBrandId = brandId || localStorage.getItem('brandId');
    if (currentBrandId) {
      handleGetBrandSegments(currentBrandId);
    }
  }, [brandId]);

  // Open the options modal when the button is clicked
  const handleOptionsButtonClick = () => {
    setOptionsModalOpen(true);
  };

  // Close the options modal
  const handleOptionsModalClose = () => {
    setOptionsModalOpen(false);
  };

  // Close the configuration modal
  const handleConfigModalClose = () => {
    setConfigModal({ open: false, content: null });
  };

  const handleOptionSelect = (type) => {
    setOptionsModalOpen(false);

    const formFields = getNodeFormFields(type, nodeTypesConfig, brandSegments, campaigns);

    if (formFields && formFields.length > 0) {
      setConfigModal({
        open: true,
        content: (
          <NodesDynamicForm
            title={`Create "${type}" Node`}
            formFields={formFields}
            onSubmit={(formData) => {
              addNode(type, formData);
              handleConfigModalClose();
            }}
            onClose={handleConfigModalClose}
          />
        ),
      });
    } else {
      addNode(type);
    }
  };

  const findNodeTypeConfig = (type) => {
    const { triggers } = nodeTypesConfig;
    return triggers.find((node) => node.type === type);
  };

  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          ...(nodesExist
            ? {
              // Position under "Go Back" button when nodes exist
              top: '125px',
              left: '10px',
            }
            : {
              // Center in screen when no nodes
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }
          ),
          zIndex: 10
        }}
      >
        <Button
          onClick={handleOptionsButtonClick}
          variant="contained"
          style={{
            borderRadius: 28,
            minWidth: 'auto',
            aspectRatio: '1 / 1',
          }}
          sx={{ backgroundColor: "#0DA3E2" }}
        >
          <AddIcon />
        </Button>
      </Box>

      {/* Options Modal */}
      <Dialog
        open={optionsModalOpen}
        onClose={handleOptionsModalClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            minWidth: '600px',
          }
        }}
      >
        <DialogTitle sx={{ textAlign: 'center' }}>Select a Trigger Node</DialogTitle>
        <DialogContent
          sx={{
            p: 3,
            pt: '24px !important',  // Override MUI's default padding-top
            pb: '24px !important',  // Override MUI's default padding-bottom
            overflow: 'hidden',     // Prevent scrollbars during hover
          }}
        >
          <Grid2
            container
            spacing={3}
            justifyContent="center"
            alignItems="center"
            sx={{
              width: '100%',
              m: 0,
              p: '12px',
            }}
          >
            {nodeTypesConfig.triggers.map((node) => (
              <Grid2
                item
                xs={12}
                sm={4}
                key={node.type}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => handleOptionSelect(node.type)}
                  sx={{
                    width: '180px',
                    height: '180px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    p: 2,
                    transition: 'all 0.2s ease-in-out',
                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                    }
                  }}
                >
                  <node.icon sx={{ fontSize: '40px', mb: 2 }} />
                  <div>{node.displayName}</div>
                </Button>
              </Grid2>
            ))}
          </Grid2>
        </DialogContent>
      </Dialog>

      {/* Configuration Modal */}
      {configModal.open && (
        <NodeConfigModal open={configModal.open} onClose={handleConfigModalClose}>
          {configModal.content}
        </NodeConfigModal>
      )}
    </>
  );
};

export default AddNodeButton;
