import React from 'react';
import { Modal, Box, Button } from '@mui/material';

const NodeConfigModal = ({ open, onClose, children }) => {
    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 600,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    borderRadius: 2,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box sx={{ p: 2 }}>
                    {children}
                </Box>
                {/* <Box sx={{ 
                    p: 2, 
                    borderTop: '1px solid',
                    borderColor: 'divider',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: 1 
                }}>
                    <Button 
                        variant="outlined" 
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                </Box> */}
            </Box>
        </Modal>
    );
};

export default NodeConfigModal;
