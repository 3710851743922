import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Box, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import NodesDynamicForm from '../../../../forms/NodesDynamicForm';
import nodeTypesConfig from '../../../../models/nodeTypesConfig';
import AddNodeContext from '../../../../contexts/AddNodeContext';
import NodeConfigModal from '../../menus/NodeConfigModal';
import CustomHandle from '../../handles/CustomHandle';
import useAutomations from '../../../../hooks/automationhooks/useAutomations';
import { useThemeContext } from '../../../../Theme/Theme';

const TriggerNodeTemplate = ({ id, type, data, selected, children }) => {
    const [configModal, setConfigModal] = useState({ open: false });
    const { editNode } = useContext(AddNodeContext);
    const { mode } = useThemeContext();
    const { brandSegments } = useAutomations();

    const handleEdit = () => {
        const nodeConfig = nodeTypesConfig.triggers.find(
            (node) => node.type === type
        );

        let formFields = [...(nodeConfig?.formFields || [])];

        if (type === 'addedToSegment') {
            formFields = formFields.map(field => {
                if (field.id === 'segment') {
                    return {
                        ...field,
                        options: brandSegments.map(segment => ({
                            value: segment.Id,
                            label: segment.Name
                        }))
                    };
                }
                return field;
            });
        }

        setConfigModal({
            open: true,
            content: (
                <NodesDynamicForm
                    title={`Edit "${nodeConfig?.displayName}" Node`}
                    formFields={formFields}
                    initialData={{
                        ...data,
                        segments: data.segments || []
                    }}
                    onSubmit={(formData) => {
                        const selectedSegments = brandSegments
                            .filter(s => formData.segments.includes(s.Id))
                            .map(s => s.Name);

                        editNode(id, {
                            ...formData,
                            segmentNames: selectedSegments
                        });
                        setConfigModal({ open: false });
                    }}
                />
            ),
        });
    };

    // Get the icon component from config
    const nodeConfig = nodeTypesConfig.triggers.find(node => node.type === type);
    const IconComponent = nodeConfig?.icon;
    const iconColor = nodeConfig?.iconColor || '#e0e0e0'; // Default gray if no color specified

    return (
        <>
            <div className={`node-template ${mode}`}
                style={{
                    minWidth: 170,
                    maxWidth: 170,
                    width: '100%',
                    minHeight: 60,
                    maxHeight: 60,
                    height: '100%',
                    borderRadius: 10
                }}>
                <Box sx={{
                    marginLeft: -1,
                    marginTop: 0.3,
                    marginRight: -1
                }}>

                    {/* Optional top header */}
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderBottom: '1px solid #444',
                        width: '100%',
                        marginTop: -18
                    }}>
                        <strong style={{ color: '#00aaff', fontSize: 8 }}>
                            {data.label}
                        </strong>
                        <IconButton size="small">
                            <EditIcon sx={{ color: '#aaa', fontSize: 10 }} />
                        </IconButton>
                    </div>

                    {/* Flexbox container for children and icon */}
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}>
                        {/* Children */}
                        <div style={{
                            flex: 1,
                            fontSize: '0.9rem',
                            lineHeight: '1.4',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}>
                            {children}
                        </div>

                        {/* Icon */}
                        {IconComponent && (
                            <Box
                                sx={{
                                    backgroundColor: iconColor,
                                    borderRadius: '50%',
                                    width: 18, // Fixed size for the container
                                    height: 18,
                                    display: 'flex',
                                    alignItems: 'center', // Centers vertically
                                    justifyContent: 'center', // Centers horizontally
                                    flexShrink: 0,
                                    mt: 0.5,
                                    color: 'white',
                                    position: 'relative', // Ensure inner icon respects container boundaries
                                    lineHeight: 0, // Remove font-based vertical misalignment
                                }}
                            >
                                <IconComponent sx={{ width: '65%', height: '75%' }} width={13} height={13} mb={0.1} /> {/* Scales icon relative to the container */}
                            </Box>

                        )}
                    </div>
                </Box>

                {/* Handles */}
                <CustomHandle
                    type="source"
                    position="bottom"
                    style={{ background: '#00aaff' }}
                />
            </div>

            {configModal.open && (
                <NodeConfigModal
                    open={configModal.open}
                    onClose={() => setConfigModal({ open: false })}
                >
                    {configModal.content}
                </NodeConfigModal>
            )}
        </>
    );
};

TriggerNodeTemplate.propTypes = {
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    selected: PropTypes.bool,
    children: PropTypes.node,
};

export default TriggerNodeTemplate;
