import React from 'react';
import ActionNodeTemplate from '../templates/ActionNodeTemplate';
import { Tooltip, Typography } from '@mui/material';

export default function SMSMessageNode({ id, type, data, selected }) {
    return (
        <ActionNodeTemplate id={id} type={type} data={data} selected={selected}>
            <Tooltip title={data.sendFrom.label} placement="top">
                <Typography sx={{ mt: 0.5 }} noWrap fontSize={8}>
                    Send From: {data.sendFrom.label}
                </Typography>
            </Tooltip>
            <Tooltip title={data.template.label}>
                <Typography noWrap fontSize={8}>
                    Template: {data.template.label}
                </Typography>
            </Tooltip>
        </ActionNodeTemplate>
    );
}
